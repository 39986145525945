import React from 'react'
import { H2, H4, Paragraph1 } from '@styles/Global.styles'
import { Grid } from 'semantic-ui-react'
import { Checkout } from '@models/Checkout/Checkout'
import { navigate } from 'gatsby'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import CheckoutSelectionCard from '@components/CheckoutSelectionCard/CheckoutSelectionCard'
import GiftingCheckoutSelectionCard from './GiftingCheckoutSelectionCard'
import CheckoutSelectionCardWithIcon from '@components/GiftingSelection/CheckoutSelectionCardWithIcon'
import { GiftingType } from './GiftingType'

export type GiftingSelectionProps = {
    heading: string
    subHeading: string
    body: string
    yesCard: CheckoutSelectionCardModel
    noCard: CheckoutSelectionCardModel
    updateIsGifting: (isGifting: boolean) => void
    updateGiftingType: (giftingType: number) => void
    checkout: Checkout
}

interface States {
    isGifting: boolean
}

class MobileGiftingSelection extends React.Component<GiftingSelectionProps, States>{
    constructor(props: GiftingSelectionProps) {
        super(props)
        this.state = {
            isGifting: false
        }
    }

    selectOption = (isGifting: boolean) => {
        this.props.updateIsGifting(isGifting);
        if(isGifting){
            this.props.updateGiftingType(1)
        } else{
            this.props.updateGiftingType(0)
        }

        this.setState({
            isGifting: isGifting
        }, () => {
            setTimeout(() => {
                var url = this.props.checkout.isGifting ? '/gifting-options-selection/' : '/personal-details/'
                navigate(url)
            }, 500);
        })
    }

    render(){

        const {subHeading, body, yesCard, noCard, checkout} = this.props;

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <H2>{subHeading}</H2>
                        <Paragraph1>
                            {body}
                        </Paragraph1>
                        <Grid stretched>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <CheckoutSelectionCardWithIcon image={"gift"} card={yesCard} onSelect={() => this.selectOption(true)} isSelected={this.state.isGifting} hideButton={true}></CheckoutSelectionCardWithIcon>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <CheckoutSelectionCard card={noCard} onSelect={() => this.selectOption(false)} isSelected={!checkout.isGifting} hideButton={true}></CheckoutSelectionCard>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>            
            </Grid>
        );
    }
}
export default MobileGiftingSelection